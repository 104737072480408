import './App.css';
import 'semantic-ui-css/semantic.min.css';
import {Button, Card, Container, Header, Segment, Table} from "semantic-ui-react";

function App() {
  return (
      <Container>
          <Segment basic>
              <Header size="huge" textAlign="center" className="headerImage">
                  <h1 id="name">Margo Mactaggart</h1>
                  <h2 id="subtitle">A Celebration of Life</h2>
              </Header>
          </Segment>

          <Segment basic textAlign="center">
              <Card.Group itemsPerRow={2}>
                  <Card>
                      <Card.Header as={Header} size="large">Ceremony</Card.Header>
                      <Card.Content>
                          <Card.Description>
                              We'll be celebrating the life of Margo Mactaggart. Please join us for a ceremony in her
                              honour. We'll be sharing stories and memories.
                          </Card.Description>
                          <Table basic size="small">
                              <Table.Body>
                                  <Table.Row>
                                      <Table.Cell className="key">Date</Table.Cell>
                                      <Table.Cell classname="value">Oct 5th 2024</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                      <Table.Cell className="key">Doors Open</Table.Cell>
                                      <Table.Cell className="value">10:30 AM</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                      <Table.Cell className="key">Ceremony</Table.Cell>
                                      <Table.Cell className="value">11-12 AM</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                      <Table.Cell className="key">Location</Table.Cell>
                                      <Table.Cell className="value">
                                          <a href="https://www.google.ca/maps/place/Celebration+Hall+and+Courtyard/@49.2353546,-123.0955144,17z/data=!4m14!1m7!3m6!1s0x548675efa82cd669:0xed6b31d2410625d2!2sCelebration+Hall+and+Courtyard!8m2!3d49.2353511!4d-123.0929395!16s%2Fg%2F11kxkw33vb!3m5!1s0x548675efa82cd669:0xed6b31d2410625d2!8m2!3d49.2353511!4d-123.0929395!16s%2Fg%2F11kxkw33vb?entry=ttu">
                                              Mountain View Celebration Hall
                                          </a>
                                      </Table.Cell>
                                  </Table.Row>
                              </Table.Body>
                          </Table>
                      </Card.Content>
                      <Card.Meta textAlign="center">
                          <Button size="huge" primary style={{marginBottom: "10px"}} as={"a"} href="https://forms.gle/A99fXmEF9DjigXJm8">RSVP Ceremony</Button>
                      </Card.Meta>
                  </Card>

                  <Card>
                      <Card.Header as={Header} size="large">Reception</Card.Header>
                      <Card.Content>
                          <Card.Description>
                              After the ceremony, we will continue to celebrate Margo's life, and her love of an open-door, with a drop-in/potluck style reception.
                          </Card.Description>
                          <Card.Meta>
                              <Table>
                                  <Table.Body>
                                      <Table.Row>
                                          <Table.Cell className="key">Date</Table.Cell>
                                          <Table.Cell className="value">Oct 5th 2024</Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                          <Table.Cell className="key">Time</Table.Cell>
                                          <Table.Cell className="value">3:30pm to 9-ish</Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                          <Table.Cell className="key">Potluck</Table.Cell>
                                          <Table.Cell className="value"><a href="https://docs.google.com/spreadsheets/d/1_mWQfkmbHgZBrzACYAMQNbizOdlMEUSFso3bLqPORhQ/edit?usp=sharing">spreadsheet</a></Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                          <Table.Cell className="key">Location</Table.Cell>
                                          <Table.Cell className="value">
                                              <a href="https://maps.app.goo.gl/MjczZ9MhfWGBvuwq8">
                                                  5626 Alma St, Vancouver, BC
                                              </a>
                                          </Table.Cell>
                                      </Table.Row>
                                  </Table.Body>
                              </Table>
                          </Card.Meta>
                      </Card.Content>
                      <Card.Meta textAlign="center">
                          <Button size="huge" primary style={{marginBottom: "10px"}} as="a" href="https://forms.gle/VcLL7fqJBNyMHRbXA">RSVP Reception</Button>
                      </Card.Meta>
                  </Card>

              </Card.Group>
          </Segment>

          <Segment basic textAlign="center">
              <Card style={{maxWidth: "500px"}} centered className="secondary">
                  <Card.Header as={Header} size="large">Share Photos</Card.Header>
                  <Card.Description>
                      We invite you to share your photos of Margo Mactaggart. Please upload your photos to our shared
                      album.
                  </Card.Description>
                  <Card.Meta textAlign="center">
                      <Button size="large" secondary as={"a"} href="https://forms.gle/26f2NgduqeAwCjBG9">Share Photos</Button>
                  </Card.Meta>
              </Card>
                <br/>
              <Card style={{maxWidth: "500px"}} centered className="secondary">
                  <Card.Header as={Header} size="large">Memory Message</Card.Header>
                  <Card.Description>
                      If you'd like, you can share your memories of Margo Mactaggart. Please leave a message in her
                      memory.
                  </Card.Description>
                  <Card.Meta textAlign="center">
                      <Button size="large" secondary as={"a"} href="https://forms.gle/nqvm6hymieQA41BV6">Memory Message</Button>
                  </Card.Meta>
              </Card>

          </Segment>

      </Container>
  );
}

export default App;
